$desktop: "(min-width: 768px)";
$dataItemHeight: 250px;
$dataItemTopHeight: 40px;
$primaryButtonColor: #218be5;
$primaryButtonHoverColor: #2180d9;
 @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

/* hide not working modules */

.sort-by, #opinion-in-account, div#final-additional-services{
	visibility:hidden !important;
}

#navMenuTop #my-favouriteies, a.btn.btn-info.btn-mobile.btn-res4th.pull-right, div.com-2 .btn.font-11{
	display: none !important;
}

/* end hide not working modules */

body	{
    font-family: 'Open Sans', sans-serif;
}

.bg-footer{
    background-color: #666666;
    color: #fff !important;

	a{
		color: #fff !important;
	}
}

#bg-footer-privace-policy{
	color: #fff;
	background-color: rgba(0, 0, 0, 0.90);
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}

.gulp{
	background-color: #333333;
	color: #fff;
}

.hrefSubmit{
	background:none!important;
	border:none;
	padding:0!important;
	color:#069;
}

.btn-default{
	color: #fff !important;
	font-weight: bold;
	background-color: rgba(102, 102, 102, 1);
	border: none;

	&:hover{
		color: #d2d2d2 !important;
		background-color: rgb(129, 129, 129);
	}
}

.btn-primary{
	color: #fff !important;
	font-weight: bold;
	background-color: $primaryButtonColor;
	border: none;

	&:hover{
		color: #d2d2d2 !important;
		background-color: $primaryButtonHoverColor;
	}
}

.btn-see-more{
	color: #333333;
	font-weight: bold;
	background-color: rgba(153, 153, 153, 1);
	border: 1px solid rgba(121, 121, 121, 1);

	&:hover{
		color: #3b3b3b;
		background-color: rgb(163, 163, 163);
	}
}

.btn-accept-cookie{
	right: 0;
	top: 0;
}

.h3-index{
	font-size: 24px;
	font-weight: bold;
}

.h4-index{
	font-size: 16px;
	font-weight: bold;
}

.gray-bar-index{
	background-color: rgba(242, 242, 242, 1);
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 13px;
}

.guidebooks-index-page{
	background-color: rgba(0, 0, 0, 0.4);
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	top: 12px;
	left: 2px;
	padding: 4px 12px;
	text-shadow: 1px 1px 0 black;
}

.guidebooks-top-description{
	background-color: rgba(0, 0, 0, 0.4);
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	top: 12px;
	left: 0;
	padding: 4px 12px;
	text-shadow: 1px 1px 0 black;
}

.guidebooks-bottom-description{
	font-size: 11px;
	position: absolute;
	bottom: 12px;
	left: 12px;
}

.nav-item:hover, input[type="submit"]:hover, #expand-price:hover, .print-me{
	cursor: pointer;
}

.btn.detail{
	background: none;
	color: #0056ff;
	font-size: 14px;
}

.rating-green{
	background-color: #1c7430;
	color: #ffffff;
}

.rating-yellow{
	background-color: #ddc100;
	color: #000;
}

.rating-red{
	background-color: #ff2620;
	color: #ffffff;
}

.txt-green{
	color: #1c7430;
}

.txt-yellow{
	color: #ddc100;
}

.txt-red{
	color: #ff2620;
}

div.red-nr-alert{
	display: inline;
	background-color: #ff2620;
	color: #ffffff;
	padding: 1px 6px;
	margin-left: 5px;
}

.udogodnienia{
    img{
      margin: 0px 5px 5px 5px;
    }
}

#enterTerm{

	border: black 1px solid;
	font-size: 16px;
	font-weight: bold;
	background-color: rgba(204, 204, 204, 1);

	&:hover{
		cursor: pointer;
	}
}

#opinion-choice{
	width: 330px;
	margin-top: 46px;
	div{
		font-family: 'ArialMT', 'Arial';
		padding: 10px 6px;
		background-color: #f1f1f1;
		display: inline-block;
		margin-right: 5px;
		margin-bottom: 5px;
		border: solid 1px #9e9e9e;
	}
	div.clicked{
		background-color: #e0e0e0;
		font-weight: bold;
	}
	div:hover{
		cursor: pointer;
	}
}

.avgBars{

	margin-bottom: 16px;

	/* Three column layout */
	.side {
		float: left;
	}

	.left{
		width: 90px;
	}

	.right{
		width: 24px;
		text-align: right;
	}

    .right-cancel{
        visibility: hidden;
		width: 16px;
		text-align: right;
	}

	.middle {
		float: left;
		margin-top: 2px;
	}

	.row{
		margin-top: 4px;
		margin-right: 0px;
		margin-left: 0px;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}

	/* The bar container */
	.bar-container {
		div{
			height: 12px;
			border-right: 1px solid gray;
		}
		border: 1px solid #828282;
		width: 100px;
		background-color: #f1f1f1;
		text-align: center;
		color: white;
	}
}

.expanded{
	padding: 10px 0px 0px 0px;
	margin-top: 10px;
	margin-left: 0px;
	border-top: 1px solid #d7d7d7;

	div{
		padding: 0px;
	}

	&>div:last-child{
		padding-left: 15px;
	}
}

.expand{
	max-width: 49px;
	padding-left: 0px;
	margin-top: 10px;
	border: solid 1px gray;
}

.favourites-compare{

	#left-side-compare{
		div{
			width: 100%;
			margin-bottom: 6px;
			&:nth-child(1){
				height: 100px;
				border-bottom: 1px solid #a0a0a0;
			}
			&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){height: 40px;}
			&:nth-child(6){height: 30px;}
			&:nth-child(7){
				height: 40px;
				margin-bottom: 30px;
			}
			&:nth-child(8){height: 30px;}
			&:nth-child(9), &:nth-child(10), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16), &:nth-child(17){height: 20px;}
			&:nth-child(11){height: 40px;}
			&:nth-child(17), &:nth-child(24), &:nth-child(30), &:nth-child(34){margin-bottom: 30px;}
			&:nth-child(18){height: 30px;}
			&:nth-child(19){height: 40px;}
			&:nth-child(20), &:nth-child(21), &:nth-child(22), &:nth-child(23), &:nth-child(24), &:nth-child(26), &:nth-child(28), &:nth-child(29), &:nth-child(30){height: 20px;}
			&:nth-child(25){height: 30px;}
		 	&:nth-child(27){height: 60px;}
			&:nth-child(31){height: 30px;}
			&:nth-child(35){height: 30px;}
			&:nth-child(32), &:nth-child(33), &:nth-child(34){height: 20px;}
			&:nth-child(36), &:nth-child(37){height: 20px;}
			&:nth-child(38){height: 240px;}
			&:nth-child(39){height: 100px;}
		}
	}

	#right-side-compare > span > div > div{
		margin-bottom: 6px;
		&:nth-child(1){height: 100px;}
		&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){height: 40px;}
		&:nth-child(6){
			height: 30px;
			& > div{
				margin-right: 2px !important;
			}
		}
		&:nth-child(7){
			height: 40px;
			margin-bottom: 30px;
		}
		&:nth-child(8){height: 30px;}
		&:nth-child(9), &:nth-child(10), &:nth-child(12), &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(16), &:nth-child(17){height: 20px;}
		&:nth-child(11){height: 40px;}
		&:nth-child(17), &:nth-child(24), &:nth-child(30), &:nth-child(34){margin-bottom: 30px;}
		&:nth-child(18){height: 30px;}
		&:nth-child(19){height: 40px;}
		&:nth-child(20), &:nth-child(21), &:nth-child(22), &:nth-child(23), &:nth-child(24), &:nth-child(26), &:nth-child(28), &:nth-child(29), &:nth-child(30){height: 20px;}
		&:nth-child(25){height: 30px;}
		&:nth-child(27){height: 60px;}
		&:nth-child(31){height: 30px;}
		&:nth-child(35){height: 30px;}
		&:nth-child(32), &:nth-child(33), &:nth-child(34){height: 20px;}
		&:nth-child(36), &:nth-child(37){height: 20px;}
		&:nth-child(38){height: 240px;}
		&:nth-child(39){height: 100px;}
	}

	.description-below-living-area{
		font-size: 10px;
		font-weight: normal !important;
		position: absolute;
		bottom: 0;
		/*left: 5px;*/
		word-wrap: break-word;
		line-height: 1.1;
	}

}

#showMoreOpinions, #showHiddenDescription{
	padding: 7px 0px;
	background-color: rgba(188, 188, 188, 1);
	border-color: rgba(148, 148, 148, 1);
	
	&:hover{
		cursor: pointer;
	}
}

#showMoreOpinions{
	display: none;
}

#rating-wrapper{
	height: 262px;
}

.to-download-description{
	color: black;

	&:hover{
		color: black;
		text-decoration: none;
	}
}

.question{
	font-size: 20px;
	font-weight: bold;
	color: #0066CC;
	margin-bottom: 20px;

	&:hover{
		cursor: pointer;
	}
}

.answer{
    display: none;
	font-size: 13px;
}

.faq-header{
	font-size: 32px;
	font-weight: bold;
}

.contact-box{
	background-color: rgba(242, 242, 242, 1);
	border: 1px solid rgba(121, 121, 121, 1);
	padding: 14px;
}

/* Style the tab */
.tab {
	float: left;
	border-right: none;
	background-color: #fff;
	width: 30%;
	height: 100%;

	/* Style the buttons inside the tab */
	.tablinks {
		display: block;
		background-color: rgb(180, 180, 180);
		color: black;
		padding: 6px 14px;
		width: 100%;
		height: 38px;
		border: 1px solid #ccc;
		border-right: none;
		outline: none;
		text-align: left;
		cursor: pointer;
		transition: 0.3s;
		font-size: 13px;
		margin-bottom: 5px;

		div{
			background-color: rgba(255, 255, 255, 1);
		}

		img{
			margin-right: 8px;
		}
	}
}

/* Create an active/current "tab button" class */
.tab div.active {
	background-color: #fff;
	font-weight: bold;
}

/* Style the tab content */
.tabcontent {
	display: none;
	float: left;
	padding: 0px 12px;
	border: 1px solid #ccc;
	width: 70%;
	height: 253px;

    .bars {
        .avgBars{
            padding-left: 20px;
			padding-right: 0px;
			& > div:hover{
				cursor: pointer;
			}
			.selected{
				background-color: lightgray;
				padding: 1px;
				font-weight: bold;
				border: 1px solid gray;

				.right-cancel{
					visibility: visible;
					text-align: center;
				}
			}
        }
        .detail-bars{
            padding-right: 8px;
            div{
              padding: 0px;
            }
        }
    }

    .rating-box-apartment{
        border-spacing: 2px;
        width: 110px;
        height: 65px;
        border: 1px solid #4b4b4b;

		span:last-child{
			font-size: 30px;
			font-weight: bold;
		}
    }
}

.overall-rating-box{
	width: 49px;
	height: 49px;
}

.center-h-v{
	text-align: center;
	display: table-cell;
	vertical-align: middle;
}

.comment-background{
	background-repeat: no-repeat;
	background-position: left top;
	margin-left: 0px;
}

.comment-row{
	margin-left: -10px;

	div{
		padding-left: 0px;
	}
}

.under-journey-type{
	color: gray;
	width: 100%;
	display: inline-block;
	text-align: center;
}

.instead-trash{
	background-color: #e7e7e7;
	padding: 5px;

	div{
		padding: 0px;
	}

	a{
		padding: 10px 25px;
	}

	a:hover{
		color: white;
	}
}

#stickyAnchor{
	visibility: visible !important;
	z-index: 10000;
	div{
		padding: 8px 20px 8px 20px;
		background-color: #fff;
		display: inline-block;
	}
}

#stickyAnchor-wrapper{
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.73);
	padding: 10px;
	margin-left: -10px;
	margin-top: -40px;
	z-index: 10000;
}

#not-Av-panel{
	font-size: 13px;
	background-color: red;
	color: white;
	text-align: left;

	a{
		color: white;
		text-decoration: underline;
	}
	a, span{
		font-weight: normal;
	}
}

.anchor-destination{
	width: 0px;
	padding-top: 100px;
	margin-top: -100px;
}

.anchor{
	margin-right: 2px;
}

#stickyReservationPanel{
	width: 380px !important;
	z-index: 1000 !important;
}

#stickyReservationPanelMobile{
	display: none;
	position: fixed;
	top: 6px;
	z-index: 2000;
}

.sticky-panel-right, .additional-service-list{
	div{
		font-weight: bold;
	}
	ul{
		margin-bottom: .5rem;
		padding-left: 20px;
	}
}

.additional-service-list-av{
	margin-bottom: .5rem;
	padding-left: 15px;
}

.results-search {
	background-color: grey;
	border-radius: 20px;
}

.form-control {
	border-radius: 0;
}

button {
	&:hover {
		cursor: pointer;
	}
}

.main-text
{

    color: #FFF;
}

.icon	{
	width: 20px !important;
}

.easy-autocomplete-container {
	z-index: 200 !important;
	}

div.easy-autocomplete{
	height: 100%;
	#region{
		height: 100%;
	}
}

select.h-100.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 4px) !important;
}

.input-group-addon {
		min-width: 40px;
	    text-align:left;
	}


.back {
		height: auto;
		background: no-repeat center;
	    background-image: none;
	    background-size: auto auto;
		background-size: cover;
}

.transparent {
					background-color: rgba(255,255,255,0.68);
	&:hover {
							background-color: rgba(255,255,255,0.9);

	}
}	


#topSearch {
	background: no-repeat center;
	background-size: cover;

	padding-bottom: 20px;

	.search {
		padding-top: 20px;
	}

	h1 {
		font-size: 24px;
		color: white;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	h4 {
		color: white;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.col {
		background-color: rgba(255, 255, 255, 0.5);
		margin: 0 auto;
		padding-bottom: 20px;
	}

	.wyszukiwarka {
		padding-top: 20px;

		button	{
			width: 100%;
		}
	}


}

#benifits {

	p {
		font-size: 14px;
	}

	h4	{
		padding: 0;
		margin: 0;
	}

	.row {
		padding-top: 20px;
	}

}

#apartamentsforyou{
	padding-top: 25px;

	a {
		margin: 0;
		padding-left: 2px;
		padding-right: 2px;
		color: black;
		text-decoration: none;
		position: relative;
	}

	.semi-transparent {
		position: absolute;
		background-color: rgba(0,0,0,0.40);
		font-size: 14px;
		bottom: 0;
		margin-bottom: 15px;
		color: white;

		h1 {
			font-size: 18px;
			font-weight: bold;
			text-shadow: 1px 1px 0 black;
		}
		h4{
			/*font-family: fantasy;*/
			font-weight: bold;
			text-shadow: 1px 1px 0 black;
		}
		p.price {
			font-size: 16px;
			/*font-family: fantasy;*/
			font-weight: bold;
			text-shadow: 1px 1px 0 black;
		}

	}

	.semi-transparent2 {
		margin-bottom: 35px !important;
		left: 2px !important;
	}

	.apartament {
		height: 176px;
		width: 100%;
		margin-bottom: 5px;
        background-repeat: no-repeat;
		background-position: center;    
		resize: both;
    	background-size: 100% 100%;
	}
}

.map-description-bottom{
	font-size: 10px;
	position: absolute;
	left: 0;
	bottom: 10px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	padding: 5px;
}

.map-count{
	bottom: 36px;
	font-weight: bold;
	font-size: 10px;
	position: absolute;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	padding: 5px 10px 5px 5px;
}

.map-description {
	&>span, &>div {
	margin-top: 7px;
	}
}

.description-bottom-right{
	background-color: rgba(255, 255, 255, 0.65);
	font-size: 10px;
	position: absolute;
	right: 5px;
	bottom: 2px;
	padding-right: 6px;
}

.description-map-bottom-right{
	font-size: 10px;
	position: absolute;
	right: 23px;
	bottom: 2px;
}

.description-map-bottom-right.desktop-none {
	font-size: 10px;
	position: absolute;
	right: 23px;
	bottom: 17px;
}

.description-below{
	padding: 5px;
	margin-bottom: 10px;
	background-color: rgba(0, 0, 0, 0.1);

	a{
		color: black;
		&:hover{
			color: black;
			text-decoration: none;
		}
	}
}

.description-below-img{
	position: relative;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center top;
	display: inline-block;
	width: 26px;
	height: 26px;
	border: solid 1px;
	margin-right: 5px;
}

.description-below-notes{
	position: relative;
	top: -10px;
	display: inline-block;
	height: 26px;
	margin-left: 7px;
}

.description-below-img.note {
	span {
		color: white;
		font-size: 10px;
		font-weight: bold;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.description-below-img{
	span {
		font-size: 8px;
		font-weight: bold;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.add-to-favourities{
	position: absolute;
	right: 5px;
	top: 5px;

	&:hover{
		cursor: pointer;
	}
}

.map-description-top{
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	left: 0;
	top: 8px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	padding: 5px 20px 5px 5px;
}

.komplex-description-top{
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	left: 0;
	top: 8px;
	background-color: rgba(33, 128, 217, 0.8);
	color: white;
	padding: 5px;
}

.map-see-more{
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.map-img-wrapper{
	position: relative;
	&:not('.opinion-to-left'){text-align: center;}
}

#mapka{
	div[style*="cursor: default; position: absolute;"]{
		/*margin-top: 30px;*/
	}
}

.map-button{

	width: 280px;
}

.mapLegend{
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	padding: 20px 10px 10px 10px;
}

.map-legend-button{
	margin-left: 40px;
}

.map-legend-toggle:hover{
	cursor: pointer;
}

ul.pagination > li:not(:last-child) > a, ul.pagination > li:not(:last-child) > span {
	margin: 0 5px 0 0 !important;
}

ul.pagination > li > a, ul.pagination > li > span {
	border: 1px solid black;
}

ul.pagination {
	display: inline-block;
	margin-top: 25px;
}

ul.pagination {
	li {
		display: inline;
	}
}

ul.pagination li a,  ul.pagination li span{

	padding: 8px !important;
	text-decoration: none;
}

ul.pagination {
	li
		{
		a{
			background-color: #f7f3ef;
			color: #1b8ce8 !important;
		}
	}
}

ul.pagination{
	li.active{
		a {
			background-color: #fff;
			color: #000 !important;
		}
	}
}

ul.pagination{
	li.disabled{
		span{
			background-color: #f7f3ef;
			color: #1b8ce8;
		}
	}
}

img.active{
	border: solid 2px;
}

.text-mobile-search{
	font-size: 12px;
}

.mapLegend{
	position: relative;
}

.map-legend-toggle{
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translate(-5%, -50%);
}

.btn.btn-mobile{
	background-color: #b2b2b2;
	padding-right: 18px !important;
	padding-left: 18px !important;
	font-size: 12px;
	border: 0.5px solid #565656;
}

.btn-reservation-gray{
	color: #333333;
	font-size: 12px;
	display: inline-block;
	white-space: pre-line;
	background-color: rgba(228, 228, 228, 1);
	border: 0.5px solid #565656;
	&:hover{
		color: #333333;
	}
}

.btn-selected{
	color: #ffffff !important;
	background-color: #565656 !important;
	border: 0.5px solid #565656;
}

.btn-filter{
	padding: 0px 7px 0px 7px;
	background-color: #b2b2b2;
	font-size: 12px;
	letter-spacing: -1px;
}

.btn-filter span{
	display: block;
	margin-bottom: -5px;
}

.btn-black{
	color: white;
	background-color: black !important;
}

#btn-back-from-comment{
	background-color: rgba(242, 242, 242, 1);
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(121, 121, 121, 1);
	border-radius: 5px;
}

.btn-opinion-gray{
	background-color: rgba(242, 242, 242, 1);
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(121, 121, 121, 1);
	border-radius: 5px;
	padding: 8px 12px;
}

select {
	height:20px;
	font-size: 12px;
}

.dropdown-menu.show:not(.reservation-4th){
	width: 1088px;
	transform: translate3d(-954px, 34px, 0px) !important;
	background-color: #b2b2b2;
	padding-right: 24px;
	padding-left: 6px;
}

.dropdown-menu.show.reservation-4th{
	background-color: rgba(1,1,1,0);
	padding: 0px;
	border: none;
}

.dropdown-menu span:not(.district){
	font-size: 11pt;
	font-weight: bold;
}

.dropdown-menu{
	font-size: 10pt;
	z-index: 1100;
}

.dropdown-menu p{
	margin-bottom: 0;
}

.dropdown-menu .row{
	margin-bottom: 22px;
}

.filter-img{
	position: relative;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center top;
	display: inline-block;
	width: 36px;
	height: 36px;
	border: solid 1px;
	margin-right: 5px;
}

.filter-img-align{
	position: relative;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	display: inline-block;
	width: 36px;
	height: 36px;
	border: solid 1px;
	margin-right: 5px;
}

.filter-img span{
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	bottom: 0;
	left: 14px
}

.filter-img.more span{
	left: 10px;
}

div > span > i.fa{
	margin-left: 5px;
}

.cenaRange #slider-range{
	display: inline-block;
	width: 120px;
	margin-left: 5px;
	margin-right: 5px;
}

.cenaRange input[type='text'] {
	display: inline-block;
	width: 50px;
}

.cenaRange #Mslider-range{
	display: inline-block;
	width: 140px;
	margin-left: 5px;
	margin-right: 5px;
}

.dropdown-menu, .filters-toggle.filters-mobile {
	input[type=checkbox]:not(:checked) + label, input[type=checkbox]:not(:checked) + span.district {
		color: gray;
	}

	input[type=checkbox]:checked + label, input[type=checkbox]:checked + span.district {
		color: blue;
	}
}

.journey-type {
	input[type=radio]:not(:checked) + label{
		color: black;
	}

	input[type=radio]:checked + label{
		color: blue;
	}
}

.display-none-permanent{
    display: none !important;
}

#addApartamentToFavourites, #deleteApartamentFromFavourites, #writeToUsClose, #writeToUsCloseConfirmation, .send-to-friends, .send-news-friends, .copy-to-clipboard, .dropdown-menu label:hover, .dropdown-menu span:hover, input[type=checkbox]:hover, label.map-legend-button:hover, a.filters-toggle, .desktop-none.filters-toggle span, .btn-res4th, #cancel-login-popup, #cancel-opinion-detail, #cancel-registration-popup, input#submit, span#log-in-inline, .trash-my-opinions:hover, #clear-favourites {
	cursor: pointer !important;
}

.desktop-none.filters-toggle span, .desktop-none.filters-toggle div{
	font-size: 12px;
}

a#resetFilters{
	text-decoration:none;
	color: black;
	margin-left: 10px;
}

.row.list-item{
	margin-top: 26px;
}

.list-item-name{
	margin-bottom: -10px;
	margin-top: -8px !important;
}

.list-item-address{
	margin-bottom: -5px;
}

.list-item-description div{
	font-size: 12px;
}

.list-item-icons{
	/*margin-top: 22px;*/
}

img.list-item{
	margin-left: 4px;
}

.list-item-last-reservation{
	height: 28px;
	color: #b2b2b2;
	font-size: 10px;
	display: flex;
	justify-content:center;
	align-content:center;
	flex-direction:column;
	border-left: solid 0.5px;
}

.list-item-description-bottom{
	font-size: 10px;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	padding: 5px 10px 5px 5px;
}

.filters-mobile p{
	margin-bottom: 0px;
}

.img-loader{
	width: 80px;
	height: 80px;
}

.fc-button-group, .fc button{
	display: inline !important;
}

.notAv{
	background-color: red !important;
	color: red !important;
}

.bg-gray{
	background-color: rgba(60, 63, 65, 0.22);
}

.pointer {
	width: 90%;
	height: 40px;
	position: relative;
	background: $primaryButtonColor;
	color: #fff;
}

.pointer:hover, .pointer-back:hover{
	color: #fff;
}

.pointer:before {
	content: "";
	position: absolute;
	right: -18px;
	bottom: -1px;
	width: 0;
	height: 0;
	border-left: 20px solid $primaryButtonColor;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent; }

a.pointer-back {
	display: inline-block;
	width:100%;
	background-size: 99% 100%;
	background-repeat: no-repeat;
	color: #000;
}

a.next-notAv{
	color: #acacac;
}

a.next-notAv:hover{
	color: #acacac;
	cursor: default;
}

.flex-box {
	display: flex;
	flex-wrap: wrap;

	span.activeBold{
		font-weight: bold;
	}
}

.reservation-item{
    z-index: 1000;
	border: solid black 1px;
	background-color: rgb(235, 234, 231);
}

.reservation-payment-method{
	position: relative;
	border: solid #cfcecc 1px;
	background-color: #f0efed;
	div:last-child{
		position: absolute;
		right: 0;
		top: 0;
	}
}

label.reservation{
	font-size: 10px;
}

.res-description{
	font-size: 10px;
}

.btn-reservation{
	padding-right: 18px !important;
	padding-left: 18px !important;
	font-size: 12px;
	border-radius: 0;
	border: 0.5px solid #000000;
	color: #0056ff !important;
	background-color: #ffffff !important;
}

.btn-reservation.selected{
	color: #000000 !important;
	background-color: #b2b2b2 !important;
	font-weight: bold;
}

.btn-at-least5{
	width: 100%;
	padding-right: 18px !important;
	padding-left: 18px !important;
	font-size: 11px;
	border-radius: 0;
	border: 0.5px solid #000000;
	color: #000000 !important;
	background-color: #fffbc3 !important;
}

label.reservation{
	position: absolute;
}

label.inline-label{
	font-size: 12px;
	position: absolute;
	left: 16px;
}

#stickyReservationPanel{
	.t-datepicker{
		.t-dates{
			padding-left: 0.3em;
			padding-right: 0em;
		}
	}
}

.privacy-policy{
	line-height: 1.8;
	ol {
		counter-reset: item
	}
	li{
		display: block
	}
	li:before {
		content: counters(item, ".") " "; counter-increment: item
	}
}

@media (min-width: 575px){
	.form-group .col-sm-9, .form-group .col-sm-6{
		padding-left: 0px !important;
	}

	.form-group{
		#postcode, #postcode_invoice{
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}
}

#wskazowki
{
	display: none;
	font-family: Tahoma;
	font-size: 11px;
	height: 140px;
	overflow: auto;
}

.adp-directions{
	width: 100%;
}

.calendar-month{
	text-align: center;
	td{
		width: 30px;
		height: 30px;
	}
	.calendar-tbody{
		font-weight: bold;
		.available{
			background-color: rgba(162, 255, 142, 0.41);
			color: rgba(26, 128, 50, 0.74);
		}
		.reserved{
			background-color: rgba(232, 232, 232, 0.34);
			color: #bfbfbf;
		}
		.pre-booking{
			background-color: rgba(232, 231, 51, 0.34);
			color: #bfbf2e;
		}
	}
}

.calendar-thead{
	font-size: 9px;
	td {
		border: 0px;
	}
}

#calendar-bar{
	border: 1px solid #e9ecef;
	position: relative;
}

.calendar-bar-arrays{
	cursor: pointer;
	position: absolute;
	top: 0;
	height: 100%;
	width: 40px;
	background-color: rgba(204, 204, 204, 0.698039215686274);
}

#calendar-bar-prev{
	left: 0;
	background-repeat: no-repeat;
	background-position: center center;
}

#calendar-bar-next{
	right: 0;
	background-repeat: no-repeat;
	background-position: center center;
}

.month-box{
  display: none;
}

.compare-bar-arrays{
	cursor: pointer;
	position: absolute;
	top: 0;
	width: 40px;
	height: 100px;
	background-color: rgba(204, 204, 204, 0.698039215686274);
}

#compare-bar-prev{
	left: 0;
	background-repeat: no-repeat;
	background-position: center center;
}

#compare-bar-next{
	right: 0;
	background-repeat: no-repeat;
	background-position: center center;
}

.favourites-box{
	display: none;
	min-width: 134px;
	max-width: 150px;
	margin: 0px 6px;
}

#first-added-favourites{
	display: none;
	position: fixed;
	right: 10px;
	top: 10px;
	background-color: #ffffff;
	border: solid 1px black;
	width: 480px;
	height: 260px;
	z-index: 1000;
	padding: 10px;

	#close-first-added{
		position: absolute;
		right: 0;
		top: 0;
		background-color: black;
		color: white;
		padding: 6px 12px;
		font-weight: bold;

		&:hover{cursor: pointer;}
	}

	button{
		position: absolute;
		right: 178px;
		bottom: 20px;
		height: 38px;
		width: 124px;
	}
}

#truncate-favourites{
	display: none;
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffff;
	border: solid 1px black;
	width: 480px;
	height: 260px;
	z-index: 3000;
	padding: 10px;

	#close-truncate{
		position: absolute;
		right: 0;
		top: 0;
		background-color: black;
		color: white;
		padding: 6px 12px;
		font-weight: bold;

		&:hover{cursor: pointer;}
	}

	button{
		position: absolute;
		bottom: 20px;
		left: 180px;
		width: 120px;
	}
}

#send-to, #send-news{
	display: none;
	position: fixed;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffff;
	border: solid 1px black;
	width: 640px;
	min-height: 260px;
	z-index: 1000;
	padding: 10px;

	#close-send-to, #close-send-news{
		position: absolute;
		right: 0;
		top: 0;
		background-color: $primaryButtonColor;
		color: white;
		padding: 6px 12px;
		font-weight: bold;

		&:hover{cursor: pointer;}
	}

	input[type="text"]{
		font-size: 13px;
		width: 480px;
		height: 40px;
	}

	input[type="submit"]:hover{cursor: pointer}

	label{font-size: 14px;}

	ul{
		list-style-type: none;
		padding-left: 0px;
	}
}

#confirm-sended-to-friends, #confirm-send-news-friends{
	display: none;
	position: fixed;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #ffffff;
	border: solid 1px black;
	width: 640px;
	height: 260px;
	z-index: 1000;
	padding: 10px;

	&>div{
		margin-top: 100px;
	}
	button{
		width: 120px;
	}
}

.detail-legend{
	width: 16px;
	height: 16px;
	display: inline-block;
	border: 1px gray solid;
	display: inline-block;
}

#detail-legend-av{
	background-color: rgba(102, 204, 0, 1);
}

#detail-legend-booked{
	background-color: rgba(204, 204, 204, 1);
}

#detail-legend-pre-booked{
	background-color: rgba(255, 255, 0, 1);
}

.txt-blue{
	color: #0056ff;
}

.nr-reviews-right{
	position: absolute;
	right: 2px;
}

.opinion-to-left{
	text-align: left !important;

}

.form-full-width{
	input.full-width{
		width: 100%;
	}
}

.data-item-top{
	min-height: $dataItemTopHeight;
	background-color: #b2b2b2;
	margin-right: 5px;
}

.data-item{
	min-height: $dataItemHeight;
	background-color: #d0d0d0;
	margin-right: 5px;
	font-size: 12px;
}

#data-item-new{
	min-height: $dataItemTopHeight + $dataItemHeight;
	display: flex;
	align-items: center;
	justify-content: center;
}

div.contact-item{
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 5px;
	border: solid 1px;
	background-color: rgba(228, 228, 228, 1);
	height: 40px;
	width: 318px;
	text-align: center;
	display:table-cell;
	vertical-align: middle;
}

.btn-res4th{
	display: inline-block;
	margin-left: 16px;
}

.fs12{
	font-size: 12px;
}

.btn-more-info{
	width: 100%;
	color: #ffffff;
	background-color: $primaryButtonColor;
	&:hover{
		color: #e3e3e3;
	}
}

.minH-90{
	min-height: 90px;
	border-top: solid #7DA0B1 1px ;
}

div.edit, div.delete{
	cursor: pointer;
}
#search-reservation{
	height: 32px;
	margin-right: 10px;
}

.font-11{
	font-size: 11px;
}

.font-12{
	font-size: 12px;
}

.font-13{
	font-size: 13px;
}

.font-14{
	font-size: 14px;
}

.font-15{
	font-size: 15px;
}

.font-16{
	font-size: 16px;
}

.font-18{
	font-size: 18px;
}

.bold{
	font-weight: bold;
}

.date-div div{
	border: black solid 1px;
	width: 44px
}

.date-div div:nth-child(1){
	padding: 5px;
	color: black;
	font-size: 22px;
	font-weight: bold;
	text-align: justify;
	-moz-text-align-last: center;
	text-align-last: center;
	background-color: white;
}

.date-div div:nth-child(2){
	padding: 1px;
	color: white;
	font-size: 14px;
	text-align: justify;
	-moz-text-align-last: center;
	text-align-last: center;
	background-color: black;
}

div.more.row{
	height: 100%;
	min-height: 80px;
	position: absolute;
	top: 0;
	right: 14px;
	z-index: 1000;
	background-color: #e8e8e8;
	div{
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
}

.btn-toggle{
	cursor: pointer;
	i{
		height: 100%;
		position: relative;
		top: 40%;
		left: 0;
	}
}

div#cancel, div#save, div#cancel-data-added, div#confirm-delete, div#cancel-delete {
	cursor: pointer;
}

#data-added, #confirm-delete-pop{
	position: fixed;
	top: 5%;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -50%);
	width: 320px;
	border: solid black 1px;
	background-color: #fff;
}

#opinion-box{
	border: black solid 1px;
	padding: 20px;
}

.rating-row{
	input, label {
		display:block;
	}
}

div.rating-item{
	float:left;
	label{
		margin-left: 3px;
	}
}

div.rating-item-description{
    position: relative;
    span{
      position: absolute;
      bottom: 15%;
    }
}

.opinion-rItem{
	background-color: #F2F2F2;
	border: #797979 solid 1px;
}

.opinion-rItem.selected{
	background-color: #999999;
}

.input-none {
	img{
		margin-right: 5px;
	}
	div.rItem{
		margin-right: 5px;
	}
	label{
		cursor: pointer;
	}
	input {
		display: none;
	}
}

.input-none-apartment-kind {
	img{
		margin-right: 5px;
	}
	div.rItem{

	}
	label{
		cursor: pointer;
	}
	input {
		display: none;
	}
}

.guide-popup{
	display: none;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: left top;
	position: relative;
	left: 8px;
	min-height: 200px;
	width: 100%;
}

.guide-mobile-popup{
	display: none;
	border: 1px solid;
	padding: 10px;
}

#guide-1:hover, #guide-2:hover, #guide-3:hover{
	cursor: pointer;
}

span#showPricePerNight, div#coupon, span#cancelCoupon, span#nameChange{
	cursor: pointer;
}

div.reservation-path{
	position: relative;
	display: inline-block;

	span.number{
		position: absolute;
		top: 6px;
		left:11px;
		font-size: 14px;
		font-weight: bold;
		color:gray;
	}
	span.active{
		color:white;
	}
	span.not-active{
		color:gray;
	}
}

#login-popup, #registration-popup, #opinion-detail-popup{
	position: fixed;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 2000;
	#login-form, #registration-form {
		position: fixed;
		width: 320px;
		border: solid black 1px;
		background-color: #fff;
		z-index: 1000;
		padding: 20px;
	}

    #login-form{
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #registration-form{
      top: 0;
      left: 50%;
      transform: translate(-50%, 10%);
    }
}

#cancel-login-popup, #cancel-registration-popup{
	position: absolute;
	top: 20px;
	right: 20px;
}

#cancel-opinion-detail{
	position: absolute;
	top: 0px;
	right: 0px;
	color: white;
	background-color: black;
	padding: 3px 10px;
}

.opinion-detal.gray-box{
	width: 100%;
	background-color: #e0e0e0;
	margin-left: 0px;

    div:first-child{
        margin-bottom: -20px;
        font-size: 22px;
        font-weight: bold;
    }

    span{
        font-size: 18px;
    }

	#opinionsAmount{
		font-size: 11px;
	}

	#opinionsAmountWrapper{
		font-size: 11px;
		font-weight: bold;
	}

	#myTotalInWords, #totalAvgInWords{
		font-size: 14px;
	}

	#myTotalWrapper, #totalAvgWrapper{
		font-family: 'ArialMT', 'Arial';
	}

}

div.user-comments{
    margin-left: 0px;

    div.comment{
      margin: 0px 0px 40px 0px;
      padding: 0px;
    }

    div.user-data{
      margin-top: 10px;
      padding-left:0px;

      div.user-data-detail{
        margin-left: 56px;
      }
    }
}

#comment-wrapper{
	margin-top: 24px;
	max-height: 170px;
	overflow:scroll;
	overflow-x:hidden;
	overflow-y:scroll;
}

.rating-opinion-detail{
	span{
		padding-left: .25rem !important;
		padding-right: .25rem !important;
		margin-left: .5rem !important;
	}
}

#form-account{
	div.row{
		margin-bottom: 10px;
	}
}
span.error{
	display: none;
	color:red;
	font-size: 12px;
}

span.error-show{
	display: inline;
}

#reservation-confirmed, #services-confirmed{
	background: #daffff;
}

.additional-service{
	border: 1px solid lightgray;
	padding: 10px 8px;

	div{
		padding: 0px !important;
	}
}

.btn-to-pay{
	&:hover {
		color: #ffffff !important;
		cursor: pointer;
	}
	color: #ffffff !important;
	background-color: $primaryButtonColor;
	font-size: 12px;
	display: inline-block;
	white-space: pre-line;
}

.trash-my-opinions{
	background-color: #e7e7e7;
	padding: 15px;
}

.tabcontent-mobile{
	background-color: rgba(242, 242, 242, 1);
}

#rating-wrapper-mobile{
	.tab {
		float: left;
		border-right: none;
		background-color: #fff;
		width: 100%;
		height: 100%;

		/* Style the buttons inside the tab */
		.tablinks {
			display: block;
			background-color: rgba(242, 242, 242, 1);
			color: black;
			padding: 6px 14px;
			width: 100%;
			height: 38px;
			border: 1px solid #ccc;
			outline: none;
			text-align: left;
			cursor: pointer;
			transition: 0.3s;
			font-size: 13px;
			margin-bottom: 5px;

			div{
				background-color: rgba(242, 242, 242, 1);
			}

			img{
				margin-right: 8px;
			}
		}
	}
}

#chooseJourneyType{
	position: absolute;
	right: 30px;
	top: 0px;
	width: 40px;
	height: 40px;
}

.tabcontent-mobile {
	border: 1px solid #ccc;
}

.totalAvgImg{
	height: 22px;
	margin-top: 2px;
}

.phone-expanded{
	position: relative;
	z-index: 2000;
}

@media (min-width: 768px) {
	.desktop-hidden{
		visibility: hidden !important;
	}

    .img-group-detail{
      height: 220px;
    }

	#calendar-bar{
		width: 95%;
	}

    #compare-bar{
		width: 100%;
	}

	.desktop-none{
		display: none !important;
	}

	.pointer-none{
		pointer-events: none !important;
	}

	.apartament-info {
		height: 380px; }

	#topSearch {
		top: 50px;
		width: 100%;
	}
	#topSearch .container {
		padding-top: 150px; }

	.flex-box {
		justify-content: space-between;
		#Rtitle{
			order: 1;
		}
		#Rpath{
			order: 2;
		}
	}

	#opinion-detail{
		top: 0;
		left: 50%;
		transform: translate(-50%, 10%);
		width: 710px;
		position: fixed;
		border: solid black 1px;
		background-color: #fff;
		z-index: 1000;
		padding: 20px;

		div.col-12{
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	.bt-md-solid{
		border-top: solid 1px;
	}

	#my-opinon-detail{
		position: absolute;
		bottom: 46px;
	}

	#helpful-wraper{
		position: absolute;
		bottom: 0px;
	}

	#enterTerm{
		float: right;
	}
}

@media (max-width: 767px){
	#stickyReservationPanel{
		margin-left: 6px;
		margin-right: 6px;
	}

	#mobileReservation{
		width: 100%;
	}

    .img-group-detail{
      height: 176px;
    }
	.sm-facilities-mb-3 div:not(:last-child), .sm-facilities{
		margin-bottom: 16px;
	}

	#calendar-bar{
		width: 90%;
	}

    #compare-bar{
		width: 100%;
	}

	#mapka{
		height: 380px !important;
	}

	.mobile-hidden{
		visibility: hidden !important;
	}

	.mobile-none{
		display: none !important;
	}

	.flex-box {
		flex-flow:column wrap;

		#Rtitle{
			order: 2;
		}
		#Rpath{
			order: 1;
		}
	}

	.form-group.row{
		label{
			font-size: 12px;
		}
	}

	.add-new-data{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		border: solid black 1px;
		background-color: #fff;
	}

	#data-added{
		width: 100%;
	}

	#form-account{
		padding-left: 20px;
		padding-right: 20px;
	}

	#login-popup, #registration-popup{
		#login-form{
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
		}

		#registration-form{
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	#opinion-detail-popup{
		position: absolute;
	}

	#opinion-detail{
		position: static;
		width: 100%;
		left: 0;
		top: 0;
		border: solid black 1px;
		background-color: #fff;
		z-index: 1000;
		padding: 20px;
		margin-left: 0px;
	}

	.map-description-top, .map-count, .map-description-bottom{
		left: 2px !important;
	}

}

@media (max-width: 316px){
	.description-map-bottom-right.desktop-none{
		position: relative;
		right: 0;
		bottom: 0;
		text-align: right;
	}

	div.inline-wrapper.text-right {
		position: relative !important;
		display: block;

	}
}

@media (min-width: 576px) and (max-width: 767px){
	.description-map-bottom-right.desktop-none{
		position: relative;
		right: 0;
		bottom: 0;
		text-align: right;
	}

	#apartamentsforyou .apartament{
		height: 150px;
	}

	#topSearch{
		padding-top: 20px;
	}
}

@media (max-width: 575px){
	.searchCont{
		padding-top: 20px;
	}
	.t-datepicker{
		.t-dates{
			padding-left: 0.3em;
			padding-right: 0em;
		}
	}
	.privacy-policy{
		ol {
			padding-left: 0 !important;
		}
		li{
			padding-left: 0.5em !important;
		}
	}
}

@media (max-width: 767px){
	.form-row.pick-date{
		padding-right: 0px;
		& > div{
			padding-right: 0px;
		}
	}

	.btn-group.user-opinion{
		position: relative;
	}

	#send-to, #send-news, #confirm-send-news-friends, #confirm-sended-to-friends{
		width: 95%;
		input[type="text"]{
			width: 100%;
		}
	}

	#truncate-favourites{
		width: 100%;
		button{
			left: 125px;
		}
	}

	#first-added-favourites{
		width: 100%;
		right: 0;
		button{
			right: 33%;
		}
	}

	#confirm-sended-to-friends{
		width: 100%;
		&>div{
			margin-top: 100px;
		}
		button{
			width: 120px;
		}
	}

	.map-legend-button{
		margin-left: 2px;
	}

	#apartamentsforyou{
		.apartament {
			background-size: 100% auto;
		}
	}

	.trash-my-opinions{
		padding: 15px;
	}

	div.rating-item{
		width: 24px;
	}

	.rating-item-description{
		font-size: 13px;
	}

}

@media (min-width: 767px){
	.btn-group.user-opinion{
		position: absolute;
		right: 0;
	}

	#printDirections{
		position: absolute;
		bottom: 4px;
		right: 0;
	}

	div.rating-item{
		width: 40px;
	}

	.rating-item-description{
		font-size: 16px;
	}

	.comment-background{
		padding-left: 2em;
	}
}

@media (min-width: 768px) and (max-width: 990px){
	.dropdown-menu.show{
		width: 720px;
		transform: translate3d(-40px, 34px, 0px) !important;
		background-color: #b2b2b2;
		padding-right: 24px;
		padding-left: 6px;
	}
	#topSearch{
		.container{
			padding-top: 90px;
			padding-bottom: 70px;
		}
	}
    .searchCont{
        padding-left: 0px;
        padding-right: 0px;
    }

	#price-per-quality-bar{
		right: 6.5% !important;
	}
}

@media (min-width: 768px) and (max-width: 1200px){
	.tablet-none{
		display: none !important;
	}

	#mobileReservation{
		width: 100%;
	}

	.add-new-data{
		z-index: 100;
		position: fixed;
		top: 2%;
		left: 50%;
		transform: translateX(-50%);
		width: 95%;
		border: solid black 1px;
		background-color: #fff;
	}

	.trash-my-opinions{
		padding: 8px;
	}

	#apartamentsforyou .apartament{
		height: 150px;
	}

}

@media (max-width: 1200px){
    #about-us-aggregate{
      padding: 0px;
    }

	#add-new-services.btn{
		min-height: 50px;
		padding-top: 7%;
	}
	.description-bottom-right{right: 0;}
	.btn-accept-cookie{
		position: relative;
	}
}

@media (min-width: 1200px){
	.else-handheld-none{
		display: none !important;
	}

	.price-per-quality{
		margin-bottom: -10px;
		max-width: 95px;
	}

    #about-us-aggregate{
      padding: 0px 160px;
    }

	.trash-my-opinions{
		padding: 12px;
	}

	#add-new-services.btn{
		min-height: 50px;
		padding-top: 14px;
	}

	.map-img-wrapper:hover {
		.map-see-more {
			display: inline;
		}
	}

	#topSearch {
		height: 400px;
		position: absolute;
		top: 50px;
		width: 100%;
		background-image: none !important;
	}
	.btn-accept-cookie{
		position: absolute;
	}
}

@media (min-width: 991px) and (max-width: 1200px){
	.dropdown-menu.show{
		width: 875px;
		transform: translate3d(-740px, 34px, 0px) !important;
		background-color: #b2b2b2;
		padding-right: 24px;
		padding-left: 6px;
	}

	.cenaRange #slider-range{
		display: inline-block;
		width: 60px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.cenaRange input[type='text'] {
		display: inline-block;
		width: 40px;
	}

	#topSearch{
		.container{
			padding-top: 130px;
			padding-bottom: 110px;
		}
	}

}

@media (max-width: 990px){
	.h1-owners{
		color: white;
	}

	.h1-owners, .h1-reservation{
		font-size: 20px;
		font-weight: bold;
	}

	.h2-owners{
		font-size: 18px;
		font-weight: bold;
	}

	.h3-owners{
		font-size: 13px;
		font-weight: bold;
	}

	.h2-guidebooks{
		font-size: 20px;
		font-weight: bold;
	}

	.h2-reservation, .h3-reservation{
		font-size: 16px;
		font-weight: bold;
	}

	.font-m-11{
		font-size: 11px;
	}

	.font-m-12{
		font-size: 12px;
	}

	.font-m-13{
		font-size: 13px;
	}

	.font-m-16{
		font-size: 16px;
	}

	.mb-sm-30{
		margin-bottom: 30px;
	}

	#writeToUsPopup, #writeToUsClosing{

		display: none;
		position: absolute;
		top:0;
		left: 0;
		background-color: rgba(13, 13, 13, 0.6);
		width: 100%;
		height: 110%;
		z-index: 1999;

		&>div{
			position: absolute;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2000;
			width: 90%;
			background-color: #fff;
			padding: 18px;
		}
	}

	.user-opinion-row{
		border-bottom: 1px solid black;
		border-top: 1px solid black;
	}
}

@media (min-width: 991px){
	.h1-owners, .h1-reservation{
		font-size: 32px;
		font-weight: bold;
	}
	.h2-owners{
		font-size: 24px;
		font-weight: bold;
	}

	.h3-owners{
		font-size: 20px;
		font-weight: bold;
	}

	.h2-guidebooks, .h2-reservation{
		font-size: 24px;
		font-weight: bold;
	}

	.h3-reservation{
		font-size: 16px;
		font-weight: bold;
	}

	.font-22-reservation{
		font-size: 22px;
	}

	#writeToUsPopup, #writeToUsClosing{

		display: none;
		position: fixed;
		top:0;
		left: 0;
		background-color: rgba(13, 13, 13, 0.6);
		width: 100%;
		height: 100%;
		z-index: 1999;

		&>div{
			position: fixed;
			top: 50px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2000;
			width: 620px;
			background-color: #fff;
			padding: 18px;
		}
	}

	.add-new-data{
		z-index: 100;
		position: fixed;
		top: 2%;
		left: 50%;
		transform: translateX(-50%);
		width: 1000px;
		border: solid black 1px;
		background-color: #fff;
	}

	.h-custom-search{
		height: 100%;
		select{
			height: 100% !important;
		}
	}
}

@media print
{
	@page {
		size: A4;
		margin: 1mm;
	}

	html, body {
		width: 1024px;
	}

	body {
		margin: 1em auto;
	}

	.noprint {display:none;}

}

/*# sourceMappingURL=homerent.css.map */